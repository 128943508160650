import "../App.css";
import "../styles/NoPage.css";

function NoPage(errCode = 404, errMsg = "This page does not exist!") {

  return (
    <div>
      <h1 className="Error404">{errCode}</h1>
      <h1>{errMsg}</h1>
    </div>
  );
}

export default NoPage;
